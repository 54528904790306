import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Illustration from "../images/svg/img.illustration.inline.svg"
import Img from "gatsby-image"

// const Header = () => (
//   <section className="home" id="home">
//     <div className="home__container bd-container bd-grid">
//       <div className="home__data" data-sal="slide-down" data-sal-delay="200" data-sal-duration="800" data-sal-easing="ease">
//         <h1 className="home__text-medium">Hello!</h1>
//         <h1 className="home__title">I'm Andi Goh</h1>
//         <h3 className="home__subtitle">Android Developer & UI/UX Designer, based in Indonesia.</h3>
//         <p className="home__description">It's certainly no way to get noticed in today's marketplace. Quality content marketing is what it takes to make a modern brand truly shine.</p>
//         <Link to="#contact" className="button">Let's Chat</Link>
//       </div>

//       {/* <Img fluid={data.placeholderImage.childImageSharp.fluid} /> */}
//       <Illustration className="home__img" data-sal="slide-down" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="800" />
//     </div>
//   </section>

// )

const Header = () => {

  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "programmer_illustration.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="home" id="home">
      <div className="home__container bd-container bd-grid">
        <div className="home__data" data-sal="slide-down" data-sal-delay="200" data-sal-duration="800" data-sal-easing="ease">
          <h1 className="home__text-medium">Hello!</h1>
          <h1 className="home__title">I'm Andi Goh</h1>
          <h3 className="home__subtitle">Android Developer & UI/UX Designer, based in Indonesia.</h3>
          <p className="home__description">It's certainly no way to get noticed in today's marketplace. Quality content marketing is what it takes to make a modern brand truly shine.</p>
          <Link to="#contact" className="button">Let's Chat</Link>
        </div>
         <Img fluid={data.placeholderImage.childImageSharp.fluid} className="home__img" />
      </div>
    </section>
  )

}

export default Header
