import React from "react"
import { Link } from "gatsby"
import Illustration from "../images/svg/img.services.inline.svg"

const Services = () => (
    <div className="sub-container">
        <section className="about section bd-container" id="services">
            <div className="about__container bd-grid">
                <div className="about__data" data-sal="slide-down" data-sal-delay="200" data-sal-duration="2000" data-sal-easing="ease">
                    <div className="vertical-line">
                        <span className="section-subtitle about__initial about__subtitle">Services</span>
                    </div>
                    <h2 className="section-title about__initial">Things I can do for my client</h2>
                    <p className="about__description">From offering in-depth user research, to helping with design and development, I can help you to bring your product to life.</p>
                    <Link to="#contact" className="button">Let's Chat</Link>
                </div>

                <Illustration className="about__img" data-sal="slide-down" data-sal-delay="200" data-sal-duration="2000" data-sal-easing="ease" />
            </div>
        </section>
    </div>

)

export default Services