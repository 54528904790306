import React from "react"
import '../styles/styles.scss'

//Import Components
import Header from '../components/header'
import Navbar from '../components/navbar'
import Services from '../components/services'
import Contact from '../components/contact'
import Footer from '../components/footer'
import Portfolio from "../components/portfolio"
import SEO from "../components/seo"
import ogImage from "../images/og-image.png"

const IndexPage = () => {

  return (
    <>
      <SEO title="Andi Goh" image={ogImage} />
      <a href="#" className="scrolltop" id="scroll-top">
        <i className="bx bx-chevron-up scrolltop__icon"></i>
      </a>
      <Navbar />
      <main className="l-main">
        <Header />
        <Services />
        <Portfolio />
        <Contact />
        <Footer />
      </main>
    </>
  )
}

export default IndexPage
